import { Globals as ReactSpringGlobals } from '@react-spring/web';

type ReactSpringKnownGlobals = Pick<ReactSpringGlobals.AnimatedGlobals, 'skipAnimation'>;

type ComponentLibraryGlobals = Readonly<
  ReactSpringKnownGlobals & {
    reduceDelay: boolean;
    logError: (info: string) => void;
    assign: (globals: Partial<Omit<ComponentLibraryGlobals, 'assign'>>) => void;
  }
>;

const logUninitializedInvariant: ComponentLibraryGlobals['logError'] = () => {
  throw new Error('You are trying to use component library prior to its initialization.');
};

const assign: ComponentLibraryGlobals['assign'] = (globals) => {
  ReactSpringGlobals.assign(globals);
  Object.assign(ComponentLibraryGlobals, globals);
};

export const ComponentLibraryGlobals: ComponentLibraryGlobals = {
  reduceDelay: false,
  logError: logUninitializedInvariant,
  assign,
};
